import {
  DocumentDuplicateIcon,
  EyeSlashIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { RunState, TextOutputPart, ErrorOutputPart, OutputPart } from "App";
import { useCallback, useState } from "react";

function RunStateLabel(props: { state: RunState }) {
  let dotCls = "";
  let textCls = "";
  let text = "";

  switch (props.state) {
    case RunState.Ready:
      textCls = "text-gray-800";
      dotCls = "bg-green-500";
      text = "Ready";
      break;
    case RunState.Generating:
      textCls =
        "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300";
      dotCls = "animate-pulse bg-green-500";
      text = "Generating";
      break;
    case RunState.Error:
      textCls = "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300";
      dotCls = "bg-red-500";
      text = "Error";
      break;
    default:
      throw "unreachable";
  }

  return (
    <span
      className={`inline-flex items-center ${textCls} text-xs font-medium px-2.5 py-0.5 rounded-full`}
    >
      <span className={`w-2 h-2 me-2 ${dotCls} rounded-full`}></span>
      <span className="inline-block">{text}</span>
    </span>
  );
}

function TokenCounts(props: { tokens: number; duration: number }) {
  return (
    <div className="text-xs font-mono text-gray-500">
      {props.tokens} tokens ({props.tokens / props.duration} t/sec)
    </div>
  );
}

function OutputFragment(props: { part: OutputPart }) {
  let { part } = props;

  switch (part.type) {
    case "text":
      return <span>{part.text}</span>;
    case "error":
      return (
        <div className="text-red-500 border-[1px] p-4 border-red-100 rounded-md">
          {part.message}
        </div>
      );
  }
}

function OutputPaneHeader(props: { runState: RunState }) {
  return (
    <div className="basis-12 pt-2 items-center shrink-0 flex bg-gray-50">
      <div className="pl-3 h-8 bg-gray-50 font-mono ">
        <RunStateLabel state={props.runState} />
      </div>
      <div className="flex-1"></div>
    </div>
  );
}

function OutputOverlayToolbar(props: {
  onOutputCopyClick: () => void;
  onOutputClearClick: () => void;
}) {
  return (
    <div className="absolute left-full -ml-[68px] -mt-3 pt-0 flex flex-row space-x-1">
      <button
        onClick={props.onOutputCopyClick}
        className="opacity-30 hover:opacity-70"
      >
        <div className="border-gray-200 border-[1px] p-1 bg-white rounded-md">
          <DocumentDuplicateIcon className="h-5 w-5 text-gray-600" />
        </div>
      </button>
      <button
        onClick={props.onOutputClearClick}
        className="opacity-30 hover:opacity-70"
      >
        <div className="border-gray-200 border-[1px] p-1 bg-white rounded-md">
          <TrashIcon className="h-5 w-5 text-gray-600" />
        </div>
      </button>
    </div>
  );
}

export default function OutputPane(props: {
  runState: RunState;
  outputParts: OutputPart[];
  onOutputCopyClick: () => void;
  onOutputClearClick: () => void;
}) {
  const [showHiddenTokens, setShowHiddenTokens] = useState(false);

  return (
    <div className="bg-white flex-1 basis-full grow-0 shrink-0 min-w-0 min-h-0 flex flex-col items-stretch">
      <OutputPaneHeader runState={props.runState} />
      <div className="p-5 bg-white border-t-[0.5px] border-t-gray-200 overflow-scroll w-full flex-1 basis-full shrink-0 grow-0">
        <OutputOverlayToolbar
          onOutputClearClick={props.onOutputClearClick}
          onOutputCopyClick={props.onOutputCopyClick}
        />
        <div className="whitespace-pre-wrap font-outputmono text-sm leading-6">
          {props.outputParts.map((part, i) => (
            <OutputFragment key={i} part={part} />
          ))}
        </div>
      </div>
    </div>
  );
}
