import SSE from "common/sse";

export function runScript(
  script: string,
  onMessage: (reply: string, done: boolean) => void,
  onError: (error: string) => void
): SSE {
  let sse = new SSE(`/api/run`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    payload: JSON.stringify({
      script,
    }),
  });

  sse.addEventListener("message", (event: any) => {
    let reply_json = event.data;
    let reply: any = null;

    try {
      //TODO look into why sse.ts is dispatching empty strings
      if (reply_json === "") {
        return;
      }

      reply = JSON.parse(reply_json);
      onMessage(reply, reply.done);
    } catch (e) {
      console.error("error parsing json", reply_json, e);
    }
  });

  sse.addEventListener("error", (event: any) => {
    let error = event.data;
    onError(error);
  });

  sse.stream();

  return sse;
}
