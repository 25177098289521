import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { RunState } from "App";
import { StopIcon, PlayIcon } from "@heroicons/react/24/solid";
import MistralLogo from "components/MistralLogo";
import MetaLogo from "components/MetaLogo";
import MsLogo from "components/MsLogo";

const items = [
  {
    name: "llama3-8b-instruct",
    href: "#",
    comingSoon: false,
    logo: MetaLogo,
  },
  {
    name: "mistral-7b-instruct",
    href: "#",
    comingSoon: true,
    logo: MistralLogo,
  },
  {
    name: "llama3-70b-instruct",
    href: "#",
    comingSoon: true,
    logo: MetaLogo,
  },
  {
    name: "phi-3-mini-128k-instruct",
    href: "#",
    comingSoon: true,
    logo: MsLogo,
  },
];

function RunOrStopButton(props: {
  runState: RunState;
  onRunClick?: () => void;
  onStopClick?: () => void;
}) {
  const buttonCls =
    "relative inline-flex items-center rounded-l-md bg-white px-3 py-0 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10";

  if (props.runState == RunState.Generating) {
    return (
      <button type="button" onClick={props.onStopClick} className={buttonCls}>
        <StopIcon
          className="-ml-0.5 mr-0.5 h-4 w-4 text-gray-600"
          aria-hidden="true"
        />
        Stop
      </button>
    );
  } else {
    return (
      <button type="button" onClick={props.onRunClick} className={buttonCls}>
        <PlayIcon
          className="-ml-0.5 mr-0.5 h-4 w-4 text-gray-600"
          aria-hidden="true"
        />
        Run
      </button>
    );
  }
}

export default function RunButton(props: {
  runState: RunState;
  onRunClick?: () => void;
  onStopClick?: () => void;
}) {
  const [model, setModel] = useState("llama3-8b-instruct");

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <RunOrStopButton
        runState={props.runState}
        onRunClick={props.onRunClick}
        onStopClick={props.onStopClick}
      />
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
          <span className="text-gray-600 inline-block text-left text-xs pl-1 w-40 font-mono">
            {model}
          </span>
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1 cursor-pointer">
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ focus }) => (
                    <a
                      onClick={
                        !item.comingSoon ? () => setModel(item.name) : undefined
                      }
                      className={`${
                        focus && !item.comingSoon
                          ? `bg-gray-100 ${
                              item.comingSoon
                                ? "text-gray-500"
                                : "text-gray-900"
                            }`
                          : `${
                              item.comingSoon
                                ? "text-gray-400"
                                : "text-gray-700"
                            }`
                      } block px-3 py-2 text-sm font-mono`}
                    >
                      {item.logo && <item.logo className="inline-block w-4" />}{" "}
                      {item.name}
                      {item.comingSoon && (
                        <span className="inline-flex items-center opacity-65 rounded-full bg-nebula-100 ml-2 px-2 py-0 text-[9px] font-sans text-nebula-500">
                          Coming Soon
                        </span>
                      )}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
