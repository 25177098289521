import "./App.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import { useCallback, useEffect, useState } from "react";
import MixlayerLogoMark from "components/MixlayerLogoMark";
import { runScript } from "api/runScript";
import {
  ArrowUpOnSquareIcon,
  FolderOpenIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import EditorPane from "EditorPane";
import OutputPane from "OutputPane";
import SSE from "common/sse";
import FeedbackDialog from "FeedbackDialog";
import { FaDiscord, FaGithub } from "react-icons/fa";
import ExamplesDialog from "ExamplesDialog";
import Examples from "@generated/Examples";

export enum RunState {
  Ready,
  Generating,
  Error,
}

export interface TextOutputPart {
  text: string;
  hidden: boolean;
  type: "text";
}

export interface ErrorOutputPart {
  message: string;
  type: "error";
}

export type OutputPart = TextOutputPart | ErrorOutputPart;

function HeaderSocialIcons() {
  return (
    <div className="flex flex-row">
      <div className="pl-2">
        <a href="https://github.com/mixlayer" target="_blank">
          <FaGithub className="fill-slate-500 hover:fill-slate-800 w-5 h-5" />
        </a>
      </div>
      <div className="pl-2">
        <a href="https://discord.gg/tXNyxuHne4" target="_blank">
          <FaDiscord className="fill-slate-500 hover:fill-slate-800 w-5 h-5" />
        </a>
      </div>
    </div>
  );
}

function AppHeader(props: { onExampleClick: (script: string) => void }) {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [examplesOpen, setExamplesOpen] = useState(false);

  return (
    <div className="flex">
      <a href="https://mixlayer.com" target="_blank">
        <MixlayerLogoMark className="hover:fill-nebula-700 fill-nebula-900 w-6" />
      </a>
      <div className="flex-1"></div>
      <div>
        <FeedbackDialog open={feedbackOpen} setOpen={setFeedbackOpen} />
        <ExamplesDialog
          open={examplesOpen}
          setOpen={setExamplesOpen}
          onExampleClick={props.onExampleClick}
        />
        {/* <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <ArrowUpOnSquareIcon
            className="pl-0.5 -mt-0.5 -ml-1 mr-1 h-5 w-5 text-gray-600"
            aria-hidden="true"
          />
          Share
        </button>
        <button
          type="button"
          onClick={() => setFeedbackOpen(true)}
          className="ml-2 inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <InboxIcon
            className="pl-0.5 -mt-0.5 -ml-1 mr-1 h-5 w-5 text-gray-600"
            aria-hidden="true"
          />
          Feedback
        </button> */}
        <button
          type="button"
          onClick={() => setExamplesOpen(true)}
          className="ml-2 inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FolderOpenIcon
            className="pl-0.5 -mt-0.5 -ml-1 mr-1 h-5 w-5 text-gray-600"
            aria-hidden="true"
          />
          Examples
        </button>
      </div>
      <div className="mt-1">
        <HeaderSocialIcons />
      </div>
    </div>
  );
}

function App() {
  const [code, setCode] = useState("");
  const [sseChannel, setSseChannel] = useState<SSE | null>(null);
  const [outputParts, setOutputParts] = useState<OutputPart[]>([]);
  const [runState, setRunState] = useState<RunState>(RunState.Ready);

  const [monacoEditor, setMonacoEditor] = useState<any | null>(null);

  const onMonacoMount = useCallback((monaco: any) => {
    setMonacoEditor(monaco);
  }, []);

  const onMonacoUnmount = useCallback(() => {
    setMonacoEditor(null);
  }, []);

  const pushOutputPart = useCallback(
    (reply: any, done: boolean) => {
      if (done) {
        setRunState(RunState.Ready);
        setSseChannel(null);
      }

      if (reply.error) {
        let nextOutputPart = {
          message: reply.error as string,
          type: "error",
        } as ErrorOutputPart;
        setOutputParts((prev) => [...prev, nextOutputPart]);
      } else {
        let nextOutputPart = {
          text: reply.text as string,
          hidden: false,
          type: "text",
        } as TextOutputPart;
        setOutputParts((prev) => [...prev, nextOutputPart]);
      }
    },
    [outputParts]
  );

  const runCode = useCallback(() => {
    setOutputParts([]);
    setRunState(RunState.Generating);

    let sse = runScript(code, pushOutputPart, (error) => {
      setRunState(RunState.Error);
      console.error(error);
    });

    setSseChannel(sse);
  }, [code]);

  const saveAndSetCode = useCallback(
    (c: string) => {
      localStorage.setItem("code", c);
      setCode(c);
    },
    [setCode]
  );

  const cancelRun = useCallback(() => {
    sseChannel?.close();
    setSseChannel(null);
    setRunState(RunState.Ready);
  }, [sseChannel]);

  const loadExample = useCallback(
    (script: string) => {
      saveAndSetCode(script);
      setOutputParts([]);

      if (monacoEditor) {
        monacoEditor.setScrollPosition({ scrollTop: 0 });
      }
    },
    [monacoEditor]
  );

  useEffect(() => {
    let codeParam = getQueryVariable("c");

    if (codeParam) {
      codeParam = atob(codeParam);
    }

    // load the intro example on first load
    setCode(
      codeParam ||
        localStorage.getItem("mxlplay_code") ||
        Examples.find((e) => e.title === "Introduction")?.script ||
        ""
    );
  }, []);

  let copyOutputToClipboard = useCallback(() => {
    let output = outputParts
      .filter((p) => p.type == "text")
      .map((p) => (p as TextOutputPart).text)
      .join("");

    navigator.clipboard.writeText(output);
  }, [outputParts]);

  let clearOutput = useCallback(() => {
    setOutputParts([]);
  }, []);

  return (
    <div className="w-full h-full flex flex-col bg-gray-100 items-stretch min-h-0">
      <div className="flex-none p-2 bg-gray-50 border-b-[0.5px] border-r-gray-300">
        <AppHeader onExampleClick={loadExample} />
      </div>
      <div className="flex-1 flex items-stretch min-h-0">
        <div className="flex items-stretch flex-1 bg-gray-50 border-r-[0.5px] border-r-gray-300 basis-1/2 grow-0 shrink-0">
          <EditorPane
            code={code}
            runState={runState}
            setCode={saveAndSetCode}
            onRunClick={runCode}
            onStopClick={cancelRun}
            onEditorMount={onMonacoMount}
            onEditorUnmount={onMonacoUnmount}
          />
        </div>
        <div className="flex items-stretch flex-1 basis-1/2 grow-0 shrink-0 min-w-0 min-h-0">
          <OutputPane
            runState={runState}
            outputParts={outputParts}
            onOutputClearClick={clearOutput}
            onOutputCopyClick={copyOutputToClipboard}
          />
        </div>
      </div>
    </div>
  );
}

export default App;

function getQueryVariable(variable: string) {
  var query = window.location.search.substring(1);
  console.log(query); //"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
