import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Examples from "@generated/Examples";

function ExampleCell(props: {
  example: any;
  idx: number;
  onClick: (s: string) => void;
}) {
  let example = props.example;
  let actionIdx = props.idx;

  return (
    <div
      key={example.title}
      className={`group relative bg-white p-4 cursor-pointer`}
      onClick={() => props.onClick(example.script)}
    >
      <div></div>
      <div className="mt-8">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          <a className="focus:outline-none">
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />
            {example.title}
          </a>
        </h3>
        <p className="mt-2 text-sm text-gray-500">{example.description}</p>
      </div>
      <span
        className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span>
    </div>
  );
}

export default function ExamplesDialog(props: {
  open: boolean;
  setOpen: (b: boolean) => void;
  onExampleClick: (script: string) => void;
}) {
  let { open, setOpen } = props;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-2 pt-3 text-left shadow-xl transition-all my-8 w-full max-w-2xl">
                <div className="sm:flex sm:items-start">
                  <div className=" text-center sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="border-b-[1px] border-slate-100 text-xl mb-2 mt-2 ml-3 font-semibold leading-6 text-gray-900 pb-4"
                    >
                      Examples
                    </Dialog.Title>
                    <div className="h-80 overflow-y-scroll">
                      <div className="divide-y divide-gray-200 overflow-hidden bg-gray-200 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                        {Examples.map((example, exampleIdx) => (
                          <ExampleCell
                            key={exampleIdx}
                            example={example}
                            idx={exampleIdx}
                            onClick={(script: string) => {
                              props.onExampleClick(script);
                              setOpen(false);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-t-[1px] border-slate-100 pt-3 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
