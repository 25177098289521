export default function MetaLogo(props: { className?: string }) {
  return (
    <svg
      className={props.className}
      viewBox="0 0 98 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4844 42.5182C10.4844 46.2245 11.2979 49.0701 12.3612 50.7916C13.7553 53.0464 15.8346 54.0017 17.9544 54.0017C20.6886 54.0017 23.1899 53.3232 28.0101 46.6565C31.8717 41.3131 36.4219 33.8126 39.4835 29.1105L44.6683 21.1443C48.27 15.6118 52.4388 9.4616 57.2186 5.29282C61.1207 1.89031 65.3299 0 69.5662 0C76.6785 0 83.4531 4.12151 88.6379 11.8515C94.3122 20.3173 97.0667 30.9806 97.0667 41.9848C97.0667 48.5266 95.7772 53.3333 93.5831 57.1308C91.4633 60.8034 87.3316 64.4726 80.3814 64.4726V54.0017C86.3325 54.0017 87.8177 48.5333 87.8177 42.2751C87.8177 33.357 85.7384 23.4599 81.1578 16.3882C77.9072 11.3722 73.6945 8.30715 69.0599 8.30715C64.0472 8.30715 60.0135 12.0878 55.4802 18.8287C53.07 22.4101 50.5958 26.7747 47.8177 31.6996L44.7595 37.1173C38.616 48.0101 37.0599 50.4911 33.9882 54.5856C28.6042 61.7553 24.0067 64.4726 17.9544 64.4726C10.7747 64.4726 6.23458 61.3637 3.42277 56.6785C1.12741 52.8608 0 47.8515 0 42.1435L10.4844 42.5182Z"
        fill="#0081FB"
      />
      <path
        d="M8 12.5907C12.8067 5.1814 19.7435 0 27.6996 0C32.3072 0 36.8878 1.36372 41.6709 5.26922C46.903 9.53927 52.4793 16.5705 59.4363 28.1587L61.9308 32.3173C67.9527 42.3494 71.3789 47.5106 73.384 49.9443C75.9629 53.07 77.7688 54.0017 80.1148 54.0017C86.0658 54.0017 87.551 48.5333 87.551 42.2751L96.8 41.9848C96.8 48.5266 95.5105 53.3333 93.3165 57.1308C91.1966 60.8034 87.065 64.4726 80.1148 64.4726C75.7941 64.4726 71.9662 63.5342 67.7333 59.541C64.4793 56.476 60.6751 51.0312 57.7485 46.1367L49.043 31.5949C44.6751 24.297 40.6684 18.8557 38.3494 16.3915C35.8548 13.7418 32.6481 10.5418 27.5308 10.5418C23.389 10.5418 19.8717 13.4481 16.9283 17.8937L8 12.5907Z"
        fill="url(#paint0_linear_1_7)"
      />
      <path
        d="M27.7975 10.5418C23.6557 10.5418 20.1384 13.4481 17.1949 17.8937C13.0329 24.1755 10.4844 33.5325 10.4844 42.5182C10.4844 46.2245 11.2979 49.0701 12.3612 50.7916L3.42277 56.6785C1.12741 52.8608 0 47.8515 0 42.1435C0 31.7637 2.84893 20.9451 8.26666 12.5907C13.0734 5.1814 20.0101 0 27.9662 0L27.7975 10.5418Z"
        fill="url(#paint1_linear_1_7)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_7"
          x1="13.4713"
          y1="39.4937"
          x2="86.4925"
          y2="43.5249"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0064E1" />
          <stop offset="0.4" stop-color="#0064E1" />
          <stop offset="0.83" stop-color="#0073EE" />
          <stop offset="1" stop-color="#0082FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_7"
          x1="15.1899"
          y1="46.9199"
          x2="15.1899"
          y2="22.2785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0082FB" />
          <stop offset="1" stop-color="#0064E0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
