import MonacoEditor from "react-monaco-editor";
import { RunState } from "App";
import RunButton from "RunButton";

export default function EditorPane(props: {
  code: string;
  runState: RunState;
  setCode: (s: string) => void;
  onRunClick?: () => void;
  onStopClick?: () => void;
  onEditorMount?: (monaco: any) => void;
  onEditorUnmount?: () => void;
}) {
  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    fontSize: 14,
    minimap: { enabled: false },
    lineNumbersMinChars: 2,
  };

  return (
    <div className="bg-gray-50 flex flex-col items-stretch flex-1">
      <div className="h-10 mt-2 flex">
        <div className="pl-2 h-8 bg-gray-50 font-mono">javascript</div>
        <div className="flex-1"></div>

        <div className="pr-2">
          <RunButton
            runState={props.runState}
            onRunClick={props.onRunClick}
            onStopClick={props.onStopClick}
          />
        </div>
      </div>
      <div className="flex-1 pl-2 bg-white border-t-[0.5px] border-t-gray-200">
        <MonacoEditor
          width="100%"
          height="100%"
          language="javascript"
          value={props.code}
          onChange={props.setCode}
          options={options}
          editorDidMount={(editor, monaco) => {
            if (props.onEditorMount) {
              props.onEditorMount(editor);
            }
          }}
          editorWillUnmount={() => {
            if (props.onEditorUnmount) {
              props.onEditorUnmount();
            }
          }}
        />
      </div>
    </div>
  );
}
